import React from 'react';  
import AuthService from "../AuthService";
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/tabulator.css?version=7.0';
import { ReactTabulator, MenuModule } from 'react-tabulator';
import * as dayjs from 'dayjs';

import { fetchWrapper } from "../FetchWrapper";

import { ZakazniciForm } from "./ZakazniciForm";
import { ZakaznikDetail } from "./ZakaznikDetail";
import { Global } from "./Global";

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from './Loader/LoadingSpinner';

import TextField from '@mui/material/TextField';

class TabulkaZakazniku extends React.Component {
    constructor(props) {
        super(props);

        this.tabRef = React.createRef();

        this.tabRenderComplete = this.tabRenderComplete.bind(this);

        this.zakaznikNew = this.zakaznikNew.bind(this);
        this.zakaznikEdit = this.zakaznikEdit.bind(this);
        this.zakaznikDelete = this.zakaznikDelete.bind(this);
        this.rowClick = this.rowClick.bind(this);
        this.nacistDetailZakaznik = this.nacistDetailZakaznik.bind(this);
    }

    tabRenderComplete() {

    }

    rowClick = (e, row) => {
        LoadingSpinner.open();

        this.nacistDetailZakaznik(row.getData().seza_id);
    };

    nacistDetailZakaznik(seza_idVybrany) {
        fetchWrapper.get(fetchWrapper.VratZakaznikDetail(seza_idVybrany))
            .then(data => {
                LoadingSpinner.close();

                this.props.ZobrazitDetailZakaznika(data);                
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error.message}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    zakaznikNew(e, cell) {
        this.props.OtevritDialogForm();
    }

    zakaznikEdit(e, cell) {
        this.props.OtevritDialogForm(cell.getRow().getData());
    }

    zakaznikDelete = async (e, cell) => {   
        const result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete smazat vybraný záznam?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result == "ano") {
            LoadingSpinner.open();
            fetchWrapper.del(fetchWrapper.VratZakazniky(), { seza_id: cell.getRow().getData().seza_id })
                .then(data => {
                    LoadingSpinner.close(); this.props.ReloadData();
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se aktualizovat data: {error.message}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });

                    console.log("Nepodařilo se aktualizovat data: {error}");
                });
        }
    }

    render() {
        const cellContextMenu = [
            /*{
                label: "<img src='./img/new.png'/> <div class='customContextmenuArea3ListItem'>Nový</div>",
                action: this.zakaznikNew
            },
            {
                label: "<img src='./img/edit.png'/> <div class='customContextmenuArea3ListItem'>Upravit</div>",
                action: this.zakaznikEdit
            },
            {
                label: "<img src='./img/delete.png'/> <div class='customContextmenuArea3ListItem'>Smazat</div>",
                action: this.zakaznikDelete
            }*/
        ];

        const columns = [
            { field: 'seza_jmeno', title: 'Jméno', width: 200, contextMenu: cellContextMenu },
            { field: 'seza_telefon', title: 'Telefon', width: 110, contextMenu: cellContextMenu },
            { field: 'seza_email', title: 'Email', width: 200, contextMenu: cellContextMenu },
            { field: 'seza_ulice', title: 'Ulice', width: 230, contextMenu: cellContextMenu },
            { field: 'seza_mesto', title: 'Město', width: 200, contextMenu: cellContextMenu },
            { field: 'seza_psc', title: 'PSČ', width: 100, contextMenu: cellContextMenu },

            /*{ field: 'uziv_role', title: 'Role', width: 200, contextMenu: cellContextMenu, formatter: function (cell, formatterParams, onRendered) {
                    //cell - the cell component
                    //formatterParams - parameters set for the column
                    //onRendered - function to call when the formatter has been rendered  
                    let role = "";
                    if (cell.getValue().includes("A")) role += "Administrátor";
                    if (cell.getValue().includes("T")) {
                        if (role !== "") role += ", "; 
                        role += "Technik";
                    }

                    return role;
                },
            },*/
            
        ];


        return (
            <>
                <div style={{ height: 400, width: '100%' }}>                    
                    <ReactTabulator
                        onRef={(r) => (this.tabRef = r)}
                        data={this.props.data}
                        columns={columns}
                        layout={'fitDataStretch'}
                        height={'100%'}
                        selectable={1}
                        events={{ renderComplete: this.tabRenderComplete, rowClick: this.rowClick }}
                    />
                </div>
            </>
        );
    }
  }



export class ZakazniciList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            dataEdit: null,
            dataZakaznici: [],
            dataZakazniciVyberZobrazeni: [],
            zobrazenyPanel: "LIST", 
            vybranyZakaznikDetail: null,
            filtrZakazniku : "",
            filtrMista : "",
        };

        this.otevritDialogForm = this.otevritDialogForm.bind(this);
        this.zavritDialogForm = this.zavritDialogForm.bind(this);
        this.novyZaznam = this.novyZaznam.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.zobrazitDetailZakaznika = this.zobrazitDetailZakaznika.bind(this);
        this.zavritDetailZakaznik = this.zavritDetailZakaznik.bind(this);
        this.zmenaFiltruZakaznika = this.zmenaFiltruZakaznika.bind(this); 
        this.zmenaFiltruMista = this.zmenaFiltruMista.bind(this);
        this.nastavitDataDoTabulky = this.nastavitDataDoTabulky.bind(this);    
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();
        this.setState({ currentUser: user });
        if (user && user.uziv_role.includes("A")) {
            this.reloadData();
        }

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }


    otevritDialogForm(data) {
        this.setState({ dataEdit: data, zobrazenyPanel: 'FORM' })
    }

    zavritDialogForm(bZmena) {
        this.setState({ dataEdit: null, zobrazenyPanel: 'LIST' })
        if (bZmena) this.reloadData();
    }

    novyZaznam() {
        this.otevritDialogForm();
    }

    reloadData() {
        LoadingSpinner.open();

        fetchWrapper.get(fetchWrapper.VratZakazniky())
            .then(data => {
                LoadingSpinner.close();

                this.nastavitDataDoTabulky(data, this.state.filtrZakazniku, this.state.filtrMista);
            })
            .catch(error => {
                LoadingSpinner.close();

                this.setState({ dataZakaznici: [] });
                
                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error.message}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });

                console.log("Nepodařilo se načíst data:" + error);
            });       
    }

    zobrazitDetailZakaznika(vybranyZakaznik) {
        this.setState({ zobrazenyPanel: 'DETAIL', vybranyZakaznikDetail: vybranyZakaznik })
    }

    zavritDetailZakaznik(bReloadData) {
        this.setState({ zobrazenyPanel: 'LIST', vybranyZakaznikDetail: null })

        if (bReloadData) this.reloadData();
    }

    nastavitDataDoTabulky(dataZakaznici, filtrZakazniku, filtrMista)
    {
        var dataVyberZobrazeni = [];
        if ("" + filtrZakazniku == "" && "" + filtrMista == "") dataVyberZobrazeni = dataZakaznici;
        else
        {
            const filtrZakaznikU = filtrZakazniku.toUpperCase().trim();
            const filtrMistoU = filtrMista.toUpperCase().trim();

             dataZakaznici.forEach((dr) => {
                if (filtrZakaznikU != "" && !dr.seza_jmeno.toUpperCase().includes(filtrZakaznikU)) return;
                if (filtrMistoU != "" && !(dr.seza_ulice + ", " + dr.seza_mesto).toUpperCase().includes(filtrMistoU)) return;

                dataVyberZobrazeni.push(dr);
             });
        }

        this.setState({ dataZakaznici: dataZakaznici, dataZakazniciVyberZobrazeni: dataVyberZobrazeni, filtrZakazniku : filtrZakazniku, filtrMista: filtrMista });
    }

    zmenaFiltruZakaznika(event) 
    {
        this.nastavitDataDoTabulky(this.state.dataZakaznici, event.target.value, this.state.filtrMista);
    }

    zmenaFiltruMista(event) 
    {
        this.nastavitDataDoTabulky(this.state.dataZakaznici, this.state.filtrZakazniku, event.target.value);
    }

    render() {
        return (
            <>
                {this.state.currentUser && this.state.currentUser.uziv_role.includes("A") &&
                    <div className="grid_12 odsazeni_bottom10">                        
                        <div style={{ display: this.state.zobrazenyPanel != 'LIST' ? 'none' : 'block' }}>
                            <h2>Zákazníci <button className="form_button" style={{ marginLeft: 20, marginRight: 40 }} onClick={this.novyZaznam}><img src="./img/new.png"></img></button>
                            <span style={{fontSize: 14, lineHeight:"30px", verticalAlign:"middle", fontWeight: "normal"}}>Filtrovat dle jména:</span> <TextField
                                                size="small"
                                                onChange={ this.zmenaFiltruZakaznika }
                                                value={this.props.filtrZakazniku}
                                                sx={{ width: "250px" }}
                                                onFocus={event => {
                                                    event.target.select();
                                                }}
                                            />&nbsp;
                            <span style={{fontSize: 14, lineHeight:"30px", verticalAlign:"middle", fontWeight: "normal"}}>Filtrovat dle místa:</span> <TextField
                                                size="small"
                                                onChange={ this.zmenaFiltruMista }
                                                value={this.props.filtrMista}
                                                sx={{ width: "250px" }}
                                                onFocus={event => {
                                                    event.target.select();
                                                }}
                                            />
                            </h2>
                            {this.state.dataZakaznici && <TabulkaZakazniku data={this.state.dataZakazniciVyberZobrazeni} OtevritDialogForm={this.otevritDialogForm} ReloadData={this.reloadData} ZobrazitDetailZakaznika={this.zobrazitDetailZakaznika} />}                            
                        </div>
                        <div className='clear'></div>
                        {this.state.zobrazenyPanel == 'FORM' && <ZakazniciForm drZakaznik={this.state.dataEdit} zavritDialog={this.zavritDialogForm} />}
                        {this.state.zobrazenyPanel == 'DETAIL' && <ZakaznikDetail vybranyZakaznikDetail={this.state.vybranyZakaznikDetail} bTlacitkoZpet={true} zavritDetail={this.zavritDetailZakaznik} />}
                    </div>
                }
                {(!this.state.currentUser || !this.state.currentUser.uziv_role.includes("A")) &&
                    <div className="grid_12 odsazeni_bottom10 odsazeni_top10">
                        <div className="error_zprava" >Nemáte oprávnění pro přístup!</div>
                    </div>
                }
            </>
        );
    }
  } 