import React, { createRef } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import styles from './SMapyV2.module.css';

import MessageBox from "../MessageBox/MessageBox";
import LoadingSpinner from "../Loader/LoadingSpinner";

export const API_KEY = 'cvEHy3xrpSCSrtndj6c3_om5c0Ef2XqNTiYKMqilnsk';

export class SMapyV2 extends React.Component {
    constructor(props) {
        super(props);

        const zoomDef = (this.props.zoom) ? this.props.zoom : 12;

        this.state = {
            isMapLoaded: false,
            lng: this.props.mistoAdresa.lng,
            lat: this.props.mistoAdresa.lat,
            zoom: zoomDef,
            map: null,
            markers: [],
            markerCenter: null
        }
    }

    mapContainer = createRef();
    img1 = createRef();

    componentDidUpdate(prevProps) {

        if (this.props.geojson != prevProps.geojson)
        {
            if (this.state.markers)
            {
                this.state.markers.forEach((m) => { m.remove(); });
            }

            var markersNew = [];

            // add markers to map
            this.props.geojson.forEach((markerData) => {
                // create a DOM element for the marker
                const el = document.createElement('div');
                el.className = 'marker';
                el.style.backgroundImage =`${markerData.properties.url}`;
                el.style.width = `${markerData.properties.iconSize[0]}px`;
                el.style.height = `${markerData.properties.iconSize[1]}px`;
                el.style.cursor = `default`;

                el.addEventListener('click', () => {
                    console.log(markerData.properties);
                    this.props.vyberZarizeniVMape(markerData.properties.data);
                });

                // add marker to map
                const marker = new maplibregl.Marker({element: el})
                    .setLngLat(markerData.geometry.coordinates)
                    .addTo(this.state.map);

                markersNew.push(marker);
            });

            this.setState({ markers: markersNew });
        }

        if (this.props.mistoAdresa.lng != prevProps.mistoAdresa.lng || this.props.mistoAdresa.lat != prevProps.mistoAdresa.lat)
        {
            this.setState({lng: this.props.mistoAdresa.lng, lat: this.props.mistoAdresa.lat});
           
            const coordCenter = [this.props.mistoAdresa.lng, this.props.mistoAdresa.lat];
            this.state.map.setCenter(coordCenter);

            this.state.markerCenter.remove();

            const MAP_ICONS = window.MAP_ICONS;

            // create a DOM element for the marker
            const el = document.createElement('div');
            el.className = 'marker';
            el.style.backgroundImage =
                'url(' + MAP_ICONS + 'home.png)';
            el.style.width = `32px`;
            el.style.height = `32px`;

            // add marker to map
            this.state.markerCenter = new maplibregl.Marker({element: el})
                .setLngLat(coordCenter)
                .addTo(this.state.map);

        }
    }

    componentDidMount() {
        if (this.state.map) return;
 
        const lng = this.state.lng;
        const lat = this.state.lat;
        const zoom = this.state.zoom;

        this.state.map = new maplibregl.Map({
            container: this.mapContainer.current,
            center: [lng, lat],
            zoom: zoom,
            style: {
                version: 8,
                sources: {
                    'basic-tiles': {
                        type: 'raster',
                        url: `https://api.mapy.cz/v1/maptiles/basic/tiles.json?apikey=${API_KEY}`,
                        tileSize: 256,
                    },
                    'markers': {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: [],
                        },
                        generateId: true
                    }
                },
                layers: [
                    {
                        id: 'tiles',
                        type: 'raster',
                        source: 'basic-tiles'
                    },
                    {
                        id: 'markers',
                        type: 'symbol',
                        source: 'markers',
                        layout: {
                            'icon-image': 'marker-icon',
                            'icon-size': window.devicePixelRatio > 1 ? 0.5 : 1,
                            'icon-allow-overlap': true,
                        },
                        paint: {},
                        filter: ['==', '$type', 'Point']
                    }
                ]
            }
        }, [API_KEY, lng, lat, zoom]);

        this.state.map.addControl(new maplibregl.NavigationControl(), 'top-right');

        if (this.props.geojson)
        {
                // add markers to map
                this.props.geojson.forEach((marker) => {
                    // create a DOM element for the marker
                    const el = document.createElement('div');
                    el.className = 'marker';
                    el.style.backgroundImage =`${marker.properties.url}`;
                    el.style.width = `${marker.properties.iconSize[0]}px`;
                    el.style.height = `${marker.properties.iconSize[1]}px`;
                    el.style.cursor = `default`;
                    el.addEventListener('click', () => {
                        this.props.vyberZarizeniVMape(marker.properties.data);
                    });

                    // add marker to map
                    new maplibregl.Marker({element: el})
                        .setLngLat(marker.geometry.coordinates)
                        .addTo(this.state.map);
                });
        }
        else
        {
            const MAP_ICONS = window.MAP_ICONS

            // create a DOM element for the marker
            const el = document.createElement('div');
            el.className = 'marker';
            el.style.backgroundImage =
                'url(' + MAP_ICONS + 'home.png)';
            el.style.width = `32px`;
            el.style.height = `32px`;

            // add marker to map
            this.state.markerCenter = new maplibregl.Marker({element: el})
                .setLngLat([lng, lat])
                .addTo(this.state.map);
        }
    }
   


    render() {
        const heightMap =  (this.props.height > 0) ? this.props.height : 400;

        return (
            <div className={styles.mapwrap} style={{height: heightMap}}>
                <div ref={this.mapContainer} className={styles.map} />
            </div>
        );

    }
}