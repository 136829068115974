import React from 'react';
import { Combobox } from "./Combobox";
import { fetchWrapper } from "../FetchWrapper";
import AuthService from "../AuthService";

import { EditorHodnotyForm } from "./EditorHodnotyForm";

import { FormControlLabel, Checkbox } from '@mui/material';

import Global from "./Global";
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from './Loader/LoadingSpinner';
import Modal from './MessageBox/Modal';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cs } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { MuiTheme } from "./MuiTheme";
import { API_KEY } from "./SMapy/SMapyV2"

export class CerpadloForm extends React.Component {
    constructor(props) {
        super(props);
               
        if (props.drZarizeni)
            this.state = {
                cerp_id: props.drZarizeni.cerp_id,
                seza_id: props.drZarizeni.seza_id,
                cerp_ulice: props.drZarizeni.cerp_ulice,
                cerp_mesto: props.drZarizeni.cerp_mesto,
                cerp_psc: props.drZarizeni.cerp_psc,
                cerp_poznamka: props.drZarizeni.cerp_poznamka,

                cerp_zruseno: props.drZarizeni.cerp_zruseno,
                cerp_zrusenodatum: Global.dateToObj(props.drZarizeni.cerp_zrusenodatum),
                cerp_datuminstalace: Global.dateToObj(props.drZarizeni.cerp_datuminstalace),
                cerp_znacka: props.drZarizeni.cerp_znacka,                                       
                cerp_model: props.drZarizeni.cerp_model,
                cerp_sn_venkovni: props.drZarizeni.cerp_sn_venkovni,
                cerp_sn_vnitrni: props.drZarizeni.cerp_sn_vnitrni,

                cerp_rokvyroby: props.drZarizeni.cerp_rokvyroby,
                cerp_datumposlednirevize: Global.dateToObj(props.drZarizeni.cerp_datumposlednirevize),
                cerp_typ: props.drZarizeni.cerp_typ,

                cerp_chladivo: props.drZarizeni.cerp_chladivo,
                cerp_pravidelnarevize: props.drZarizeni.cerp_pravidelnarevize,

                cerp_mapa_lng: props.drZarizeni.cerp_mapa_lng,
                cerp_mapa_lat: props.drZarizeni.cerp_mapa_lat,
                cerp_mapa_trasa_km: props.drZarizeni.cerp_mapa_trasa_km,
                cerp_mapa_trasa_sec: props.drZarizeni.cerp_mapa_trasa_sec,

                cerp_qrkod: props.drZarizeni.cerp_qrkod,

                cerp_instaloval: props.drZarizeni.cerp_instaloval,
                cerp_spustil: props.drZarizeni.cerp_spustil,

                cerp_dalsirevize_datum: Global.dateToObj(props.drZarizeni.cerp_dalsirevize_datum),
                cerp_dalsirevize_poznamka: props.drZarizeni.cerp_dalsirevize_poznamka,

                bNovy: false,
                adresaNaseptavac: "",
                adresaNaseptavacShody: [],
                adresaQueryCache: []
            };
        else this.state = {
            cerp_id: -1,
            seza_id: props.vybranyZakaznikDetail.zakaznik.seza_id,
            cerp_ulice: props.vybranyZakaznikDetail.zakaznik.seza_ulice,
            cerp_mesto: props.vybranyZakaznikDetail.zakaznik.seza_mesto,
            cerp_psc: props.vybranyZakaznikDetail.zakaznik.seza_psc,
            cerp_poznamka: "",

            cerp_zruseno: false,
            cerp_zrusenodatum: Global.dateToObj(Global.DateEmpty),
            cerp_datuminstalace: Global.dateToObj(Global.toISOString(new Date())),
            cerp_znacka: "",

            cerp_model: "",
            cerp_sn_venkovni: "",
            cerp_sn_vnitrni: "", 

            cerp_rokvyroby: "",
            cerp_datumposlednirevize: Global.dateToObj(Global.DateEmpty),
            cerp_typ: 0,

            cerp_chladivo: "",
            cerp_pravidelnarevize: true,

            cerp_mapa_lng: "",
            cerp_mapa_lat: "",
            cerp_mapa_trasa_km: 0,
            cerp_mapa_trasa_sec: 0,

            cerp_qrkod: "",
            cerp_instaloval: "",
            cerp_spustil: "",


            cerp_dalsirevize_datum: Global.dateToObj(Global.DateEmpty),
            cerp_dalsirevize_poznamka: "",

            bNovy: true,
            adresaNaseptavac: "",
            adresaNaseptavacShody: [],
            adresaQueryCache: []
        };

        this.formSubmit = this.formSubmit.bind(this);
        this.zavritDialog = this.zavritDialog.bind(this);
        this.ulozitDataDoDB = this.ulozitDataDoDB.bind(this);
        this.naseptavacGetItems = this.naseptavacGetItems.bind(this);
        this.naseptavacVyberAdresy = this.naseptavacVyberAdresy.bind(this);

    }

    /*componentDidMount() {
        this.inicializace();

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        this.inicializace();
    }

    inicializace() {

        this.setState({ loading: true });
        fetchWrapper.get(fetchWrapper.VratZakazniky())
            .then(data => {

            })
            .catch(error => {
                this.setState({ loading: false });

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst číselník: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }*/

    naseptavacGetItems = async (novaAdresa) => {
        if (this.state.adresaQueryCache[novaAdresa]) {
            this.setState({ adresaNaseptavac: novaAdresa, adresaNaseptavacShody: this.state.adresaQueryCache[novaAdresa] });
        }

        try {
            // you need to use your own api key!
            const fetchData = await fetch(`https://api.mapy.cz/v1/suggest?lang=cs&limit=10&type=regional.address&apikey=${API_KEY}&query=${novaAdresa}`);
            const jsonData = await fetchData.json();
            // map values to { value, data }
            const items = jsonData.items.map(item => ({
                value: item.name,
                data: item,
            }));

            // save to cache
            const adresyCache = this.state.adresaQueryCache;
            adresyCache[novaAdresa] = items;

            this.setState({ adresaNaseptavac: novaAdresa, adresaNaseptavacShody: items, adresaQueryCache: adresyCache });

            return items;
        } catch (exc) {
            const adresyCache = this.state.adresaQueryCache;
            adresyCache[novaAdresa] = [];

            this.setState({ adresaNaseptavac: novaAdresa, adresaNaseptavacShody: [], adresaQueryCache: adresyCache });
            return [];
        }
    }

    naseptavacVyberAdresy(objAdresy) {
        const zip = objAdresy.data.zip;
        const ulice = "";

        const regionalStructure = objAdresy.data.regionalStructure;
        const streets = ['regional.street', 'regional.municipality_part'].filter(x => regionalStructure.some(y => y.type === x)).map(x => regionalStructure.find(y => y.type === x).name)

        let street = streets.length > 0 ? streets[0] : null;

        const mesto = regionalStructure.find(x => x.type === 'regional.municipality').name;

        const address = regionalStructure.find(x => x.type === 'regional.address');
        if (address && !street.endsWith(" " + address.name)) street = street + " " + address.name;

        const country = regionalStructure.find(x => x.type === 'regional.country');
        let stat = (country) ? country.name : "";

        this.setState({ adresaNaseptavac: "", adresaNaseptavacShody: [], cerp_ulice: street, cerp_mesto: mesto, cerp_psc: zip });
    }



    formSubmit(event) {
        event.preventDefault();
        this.ulozitDataDoDB();

        /*const adresa = this.state.cerp_ulice + ", " + this.state.cerp_psc + " " + this.state.cerp_mesto;

        const zapsatSouradnice = (gps) => {
            if (gps) this.setState({ cerp_mapa: gps });
            console.log(gps);
            this.ulozitDataDoDB();
        };

        new window.SMap.Geocoder(adresa, function (geocoder) {
            const results = geocoder.getResults()[0].results;

            if (results && results.length > 0) {

                const bodCil = results[0].coords;
                const gps = bodCil.toWGS84(2).reverse().join(" ");

                zapsatSouradnice(gps);
                return;
            }

            zapsatSouradnice(null);
        }); */       
    }

    ulozitDataDoDB() {
        let chyba = "";
        if (this.state.cerp_znacka == "" || this.state.cerp_model == "" || this.state.cerp_sn_venkovni == "") chyba = chyba + " Nebyly nasataveny základní parametry značka, model, výrobní číslo.";
        if (this.state.cerp_ulice == "" || this.state.cerp_mesto == "" || this.state.cerp_psc == "") chyba = chyba + " Nebyla nastavena adresa instalace.";


        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p>{chyba}</p>,
                buttons: [
                    { name: "OK", handler: () => "OK" }
                ]
            });

            return;
        }

        const zarData = {
            cerp_id: this.state.cerp_id,
            seza_id: this.state.seza_id,
            cerp_ulice: this.state.cerp_ulice,
            cerp_mesto: this.state.cerp_mesto,
            cerp_psc: this.state.cerp_psc,

            cerp_poznamka: this.state.cerp_poznamka,
            cerp_zruseno: this.state.cerp_zruseno,
            cerp_zrusenodatum: Global.dateToStr(this.state.cerp_zrusenodatum),

            cerp_datuminstalace: Global.dateToStr(this.state.cerp_datuminstalace),
            cerp_instaloval: this.state.cerp_instaloval,
            cerp_spustil: this.state.cerp_spustil,

            cerp_znacka: this.state.cerp_znacka,
            cerp_model: this.state.cerp_model,
            cerp_sn_venkovni: this.state.cerp_sn_venkovni,
            cerp_sn_vnitrni: this.state.cerp_sn_vnitrni,

            cerp_rokvyroby: this.state.cerp_rokvyroby,

            cerp_datumposlednirevize: Global.dateToStr(this.state.cerp_datumposlednirevize),

            cerp_typ: this.state.cerp_typ,
            cerp_chladivo: this.state.cerp_chladivo,

            cerp_pravidelnarevize: this.state.cerp_pravidelnarevize,

            cerp_mapa_lng: this.state.cerp_mapa_lng,
            cerp_mapa_lat: this.state.cerp_mapa_lat,
            cerp_mapa_trasa_km: this.state.cerp_mapa_trasa_km,
            cerp_mapa_trasa_sec: this.state.cerp_mapa_trasa_sec,

            cerp_qrkod: this.state.cerp_qrkod,

            cerp_dalsirevize_datum: Global.dateToStr(this.state.cerp_dalsirevize_datum),
            cerp_dalsirevize_poznamka: this.state.cerp_dalsirevize_poznamka
        };


        LoadingSpinner.open();
        if (this.state.cerp_id > 0) {
            fetchWrapper.put(fetchWrapper.VratServisCerpadlo(), zarData).then(data => {
                LoadingSpinner.close();

                this.props.drZarizeni.cerp_ulice = this.state.cerp_ulice;
                this.props.drZarizeni.cerp_mesto = this.state.cerp_mesto;
                this.props.drZarizeni.cerp_psc = this.state.cerp_psc;

                this.props.drZarizeni.cerp_poznamka = this.state.cerp_poznamka;
                this.props.drZarizeni.cerp_zruseno = this.state.cerp_zruseno;
                this.props.drZarizeni.cerp_zrusenodatum = Global.dateToStr(this.state.cerp_zrusenodatum);
                this.props.drZarizeni.cerp_datuminstalace = Global.dateToStr(this.state.cerp_datuminstalace);

                this.props.drZarizeni.cerp_znacka = this.state.cerp_znacka;
                this.props.drZarizeni.cerp_model = this.state.cerp_model;
                this.props.drZarizeni.cerp_sn_venkovni = this.state.cerp_sn_venkovni;
                this.props.drZarizeni.cerp_sn_vnitrni = this.state.cerp_sn_vnitrni;
                this.props.drZarizeni.cerp_rokvyroby = this.state.cerp_rokvyroby;
                this.props.drZarizeni.cerp_typ = this.state.cerp_typ;
                this.props.drZarizeni.cerp_chladivo = this.state.cerp_chladivo;

                this.props.drZarizeni.cerp_datumposlednirevize = Global.dateToStr(this.state.cerp_datumposlednirevize);

                this.props.drZarizeni.cerp_pravidelnarevize = this.state.cerp_pravidelnarevize;

                this.props.drZarizeni.cerp_mapa_lng = data.cerp_mapa_lng;
                this.props.drZarizeni.cerp_mapa_lat = data.cerp_mapa_lat;
                this.props.drZarizeni.cerp_mapa_trasa_km = data.cerp_mapa_trasa_km;
                this.props.drZarizeni.cerp_mapa_trasa_sec = data.cerp_mapa_trasa_sec;

                this.props.drZarizeni.cerp_qrkod = this.state.cerp_qrkod;

                this.props.drZarizeni.cerp_instaloval = this.state.cerp_instaloval;
                this.props.drZarizeni.cerp_spustil = this.state.cerp_spustil;

                this.props.drZarizeni.cerp_dalsirevize_datum = Global.dateToStr(this.state.cerp_dalsirevize_datum);
                this.props.drZarizeni.cerp_dalsirevize_poznamka = this.state.cerp_dalsirevize_poznamka;

                this.props.zavritDialog(true);
            }).catch(error => {
                LoadingSpinner.close();
                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se uložit data: {error.message}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
        }
        else {
            fetchWrapper.post(fetchWrapper.VratServisCerpadlo(), zarData).then(data => {
                LoadingSpinner.close();
                zarData.cerp_id = data.cerp_id;
                zarData.cerp_mapa_lng = data.cerp_mapa_lng;
                zarData.cerp_mapa_lat = data.cerp_mapa_lat;
                zarData.cerp_mapa_trasa_km = data.cerp_mapa_trasa_km;
                zarData.cerp_mapa_trasa_sec = data.cerp_mapa_trasa_sec;

                this.props.vybranyZakaznikDetail.cerpadla.push(zarData);


                this.props.zavritDialog(true);
            })
                .catch(error => {
                    LoadingSpinner.close();
                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se uložit data: {error.message}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
    }

    zavritDialog() {
        this.props.zavritDialog(false);
    }



    render() {
        const nadpis = (this.state.bNovy ? "Nové zařízení" : "Upravit zařízení");

        return (
            <ThemeProvider theme={MuiTheme}>
                <LocalizationProvider adapterLocale={cs} dateAdapter={AdapterDateFns}>
            <form onSubmit={this.formSubmit}>
                <h1>{nadpis}</h1>

                <h3>Základní údaje</h3>
                <div className="form_udaj_container">
                            <EditorHodnotyForm popis="Značka:" hodnota={this.state.cerp_znacka} widthLeft={160} stylesRight={{ maxWidth: 300 }} typ="TEXT" onChange={e => this.setState({ cerp_znacka: e.target.value })} />
                </div>
                <div className="form_udaj_container">
                            <EditorHodnotyForm popis="Model:" hodnota={this.state.cerp_model} widthLeft={160} stylesRight={{ maxWidth: 300 }} typ="TEXT" onChange={e => this.setState({ cerp_model: e.target.value })} />
                </div>
                <div className="form_udaj_container">
                            <EditorHodnotyForm popis="Typ:" hodnota={this.state.cerp_typ} widthLeft={160} stylesRight={{ maxWidth: 300 }} typ="TYPCERPADLA" onChange={e => this.setState({ cerp_typ: e.target.value })} />
                </div>
                <div className="form_udaj_container">
                       <EditorHodnotyForm popis="S/N (venkovní):" hodnota={this.state.cerp_sn_venkovni} widthLeft={160} stylesRight={{ maxWidth: 250 }} typ="TEXT" onChange={e => this.setState({ cerp_sn_venkovni: e.target.value })} />
                </div>

                {this.state.cerp_typ == 1 && 
                    <div className="form_udaj_container">
                           <EditorHodnotyForm popis="S/N (vnitřní):" hodnota={this.state.cerp_sn_vnitrni} widthLeft={160} stylesRight={{ maxWidth: 250 }} typ="TEXT" onChange={e => this.setState({ cerp_sn_vnitrni: e.target.value })} />
                    </div>
                }
                <div className="form_udaj_container">
                            <EditorHodnotyForm popis="Chladivo:" hodnota={this.state.cerp_chladivo} widthLeft={160} stylesRight={{ maxWidth: 300 }} typ="TEXT" onChange={e => this.setState({ cerp_chladivo: e.target.value })} />
                </div>


                <div className="odsazeni_bottom10 odsazeni_top10"> 
                <h3>Místo instalace</h3>

                    <div className="form_udaj_container">
                                <EditorHodnotyForm popis="Našeptáváč adresy:" dataCiselnik={this.state.adresaNaseptavacShody} hodnota={this.state.adresaNaseptavac} widthLeft={160} stylesRight={{ maxWidth: 500 }} typ="NASEPTAVAC" onChange={e => this.naseptavacGetItems(e.target.value)} onItemChange={obj => this.naseptavacVyberAdresy(obj)} />
                    </div> 
                    <div className="form_udaj_container">
                                <EditorHodnotyForm popis="Ulice:" hodnota={this.state.cerp_ulice} widthLeft={160} stylesRight={{ maxWidth: 500 }} typ="TEXT" onChange={e => this.setState({ cerp_ulice: e.target.value })} />
                    </div>                

                    <div className="form_udaj_container">
                                <EditorHodnotyForm popis="Město:" hodnota={this.state.cerp_mesto} widthLeft={160} stylesRight={{ maxWidth: 500 }} typ="TEXT" onChange={e => this.setState({ cerp_mesto: e.target.value })} />
                    </div>

                    <div className="form_udaj_container">
                                <EditorHodnotyForm popis="PSČ:" hodnota={this.state.cerp_psc} widthLeft={160} stylesRight={{ maxWidth: 80 }} typ="TEXT" onChange={e => this.setState({ cerp_psc: e.target.value })} />
                    </div>
                </div>


                <div className="odsazeni_top10">     
                   <div className="form_udaj_container">
                        <div className="form_udaj_container">
                                    <EditorHodnotyForm popis="Datum instalace:" hodnota={this.state.cerp_datuminstalace} widthLeft={160} stylesRight={{ maxWidth: 200 }} typ="DATE" onChange={val => this.setState({ cerp_datuminstalace: val }) } />
                        </div>

                        <div className="form_udaj_container">
                            <EditorHodnotyForm popis="Instalováno jinou firmou:" hodnota={this.state.cerp_instaloval} widthLeft={160} stylesRight={{ maxWidth: 500 }} typ="TEXT" onChange={e => this.setState({ cerp_instaloval: e.target.value })} />
                        </div>
                        <div className="form_udaj_container">
                            <EditorHodnotyForm popis="Spuštěno jinou firmou:" hodnota={this.state.cerp_spustil} widthLeft={160} stylesRight={{ maxWidth: 500 }} typ="TEXT" onChange={e => this.setState({ cerp_spustil: e.target.value })} />
                        </div>

                       <div className="form_udaj_container odsazeni_top10">
                                    <EditorHodnotyForm popis="Pravidelná revize" hodnota={this.state.cerp_pravidelnarevize} widthLeft={160} stylesRight={{ maxWidth: 100 }} typ="CHECKBOX" onChange={e => this.setState({ cerp_pravidelnarevize: e.target.checked ? true : false })} />
                        </div>
                        <div className="form_udaj_container">
                                    <EditorHodnotyForm popis="Datum posl.revize:" hodnota={this.state.cerp_datumposlednirevize} widthLeft={160} stylesRight={{ maxWidth: 200 }} typ="DATE" onChange={val => this.setState({ cerp_datumposlednirevize: val })} />
                        </div>

                                <EditorHodnotyForm popis="Poznámka:" hodnota={this.state.cerp_poznamka} widthLeft={160} stylesRight={{ maxWidth: 500 }} typ="TEXTAREA" onChange={e => this.setState({ cerp_poznamka: e.target.value })} />
                    </div>
                </div>


                <div className="odsazeni_bottom10 odsazeni_top10">
                    <div className="form_udaj_container">
                                <EditorHodnotyForm popis="Zrušeno" hodnota={this.state.cerp_zruseno} widthLeft={160} stylesRight={{ maxWidth: 50 }} typ="CHECKBOX" onChange={e => this.setState({ cerp_zruseno: e.target.checked ? true : false })} />
                    </div>
                    {this.state.cerp_zruseno &&
                        <div>
                            <div className="form_udaj_container">
                                        <EditorHodnotyForm popis="Datum zrušení:" hodnota={this.state.cerp_zrusenodatum} widthLeft={160} stylesRight={{ maxWidth: 200 }} typ="DATE" onChange={val => this.setState({ cerp_zrusenodatum: val })} />
                            </div>
                        </div>
                    }
                </div>


                <div className="form_udaj_container">
                    <div className="left" style={{ width: 120 }}> </div>
                    <div className="right">
                        <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                        <button type="submit" className="form_button" style={{ marginLeft: 7 }}>Uložit</button>
                    </div>
                </div>
                    </form>
                </LocalizationProvider>
            </ThemeProvider>
        );
    }
}