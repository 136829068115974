import React from 'react';
import styles from './EditorHodnotyForm.module.css'
import { Combobox } from './Combobox'
import { Global } from './Global'

import TextField from '@mui/material/TextField';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';

const PanelHodnoty = ({ children, props }) => {
        if (props.popisLineHeight > 0)
        {
            return (        
                <div className={styles.detail_udaj_container} style={props.stylesParent}>
                    <div className={styles.left} style={{ width: props.widthLeft, lineHeight: props.popisLineHeight + 'px' }}>            
                    {props.popis} {props.img && <img src={props.img} />}
                    </div>
                    <div className={styles.right} style={props.stylesRight} >
                        {children}
                    </div>
                </div>);
        }
        else {
            return (        
                <div className={styles.detail_udaj_container} style={props.stylesParent}>
                    <div className={styles.left} style={{ width: props.widthLeft }}>            
                    {props.popis} {props.img && <img src={props.img} />}
                    </div>
                    <div className={styles.right} style={props.stylesRight} >
                        {children}
                    </div>
                </div>);
        }
}

export class EditorHodnotyForm extends React.Component {
    constructor(props) {
        super(props);
        /*this.rgxDesetinnyCisla = /^[+-]?([0-9]*[\\.,])?[0-9]*$/;
        this.rgxCelaCisla = /^[+-]?[0-9]*$/;*/
    }

    

    render() {
        if (this.props.typ === "DATETIME") {
            return (
                <PanelHodnoty props={this.props}>
                    <DesktopDateTimePicker renderInput={(params) => <TextField onKeyDown={e => e.preventDefault()} {...params} />}
                        inputFormat="dd.MM.yyyy HH:mm"
                        value={this.props.hodnota}
                        onChange={this.props.onChange}
                        onOpen={() => { }}
                        ampm={false}
                        inputProps={{
                            style: {
                                padding: 5
                            }
                        }}
                        componentsProps={{
                            actionBar: {
                                actions: ["today"]

                            }
                        }}
                    />
                </PanelHodnoty>
            );
        }
        else if (this.props.typ === "DATE") {
            return (
                <PanelHodnoty props={this.props}>
                    <DesktopDatePicker renderInput={(params) => <TextField onKeyDown={e => e.preventDefault()} {...params} />}
                        inputFormat="dd.MM.yyyy"
                        value={this.props.hodnota}
                        onChange={this.props.onChange}
                        onOpen={() => { }}
                        ampm={false}
                        inputProps={{
                            style: {
                                padding: 5
                            }
                        }}
                        componentsProps={{
                            actionBar: {
                                actions: ["today"]

                            }
                        }}
                    />
                </PanelHodnoty>
            );
        }

        else if (this.props.typ === "CHECKBOX") {
            return (
                <PanelHodnoty props={this.props}>                   
                    <Checkbox
                        checked={this.props.hodnota}
                        disabled={this.props.disabled}
                        onClick={(event) => {
                            event.target.checked = !this.props.hodnota;
                            this.props.onChange(event);
                        }}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        style={{ padding: 4 }}
                    />  
                    {this.props.popisRight}                   
                </PanelHodnoty >

            );

        }

        else if (this.props.typ === "TYPCERPADLA") {
            return (
                <PanelHodnoty props={this.props}>
                    <FormControl sx={{ minWidth: 140 }}>
                        <Select value={this.props.hodnota} onChange={this.props.onChange} size="small" sx={{ minWidth: 100 }} inputProps={{ MenuProps: { disableScrollLock: true } }}>
                            <MenuItem value="0">Monoblok</MenuItem>
                            <MenuItem value="1">Split</MenuItem>
                            <MenuItem value="-1">Jiné</MenuItem>
                        </Select>
                    </FormControl>
                </PanelHodnoty >
            );
        }

        else if (this.props.typ === "TYPREVIZE") {
            return (
                <PanelHodnoty props={this.props}>
                    <FormControl sx={{ minWidth: 140 }}>
                        <Select value={this.props.hodnota} onChange={this.props.onChange} size="small" sx={{ minWidth: 200 }} inputProps={{ MenuProps: { disableScrollLock: true } }}>
                            <MenuItem value="PP">Předávací protokol</MenuItem>
                            <MenuItem value="SK">Servisní kontrola</MenuItem>
                            <MenuItem value="RE">Reklamace</MenuItem>
                            <MenuItem value="J">Jiné</MenuItem>
                        </Select>
                    </FormControl>
                </PanelHodnoty >
            );
        }


        else if (this.props.typ === "CISLO") {
            return (
                <PanelHodnoty props={this.props}>
                    <TextField
                        onKeyPress={(event) => {
                            if (!/[0-9\\-]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        type="number"
                        label=""
                        size="small"
                        onChange={(e) => this.props.onChange(e)}
                        value={this.props.hodnota}
                        disabled={this.props.disabled}
                        onFocus={event => {
                            if (!this.props.disabled) event.target.select();
                        }}
                    />
                </PanelHodnoty>
            );
        }
        else if (this.props.typ === "CISLOD") {

            return (
                <PanelHodnoty props={this.props}>
                    <TextField
                        onKeyPress={(event) => {
                            /*if (event.key == ",") {
                                //event.preventDefault();
                                console.log(event.target.value + "." + "0");

                                this.props.onChange({ target: { value: parseFloat(event.target.value + ".0")  } });
                            }*/

                            //if (!(event.target.value + event.key).match(this.rgxDesetinnyCisla)) event.preventDefault();
                        }}
                        onPaste={(event) => {
                            event.preventDefault();

                            let val = event.clipboardData.getData("text");
                            if (val) {
                                val = val.replace(",", ".");
                                if (val.match(this.rgxDesetinnyCisla)) {
                                    console.log(val);
                                    console.log(parseFloat(val));
                                    this.props.onChange({ target: { value: parseFloat(val) } })
                                }
                            }
                        }}
                        type="number"
                        inputProps={{ /*lang: "en-US",*/ style: { textAlign: 'right' } }}
                        label=""
                        size="small"
                        onChange={(e) => { this.props.onChange(e) }}
                        value={this.props.hodnota}
                        disabled={this.props.disabled}
                        onFocus={event => {
                            if (!this.props.disabled) event.target.select();
                        }}
                        sx={{ textAlign: "right" } }
                    />

                    {/*<input type="text"
                            className={styles.formHodnCislo}
                            onKeyPress={(event) => {
                                if (!/[0-9\\.\\-]/.test(event.key)) {
                                    event.preventDefault();
                                }
                                else if (event.key == "." && event.target.value.includes(".")) { //jiz obsahuje tecku
                                    event.preventDefault();
                                }
                                else if (event.key == "-" && event.target.value.length != 0) { //jiz obsahuje tecku
                                    event.preventDefault();
                                }
                            }}
                            value={this.props.hodnota}
                            onChange={this.props.onChange}
                            disabled={(this.props.disabled) ? "disabled" : ""}
                        />*/}
                </PanelHodnoty >
            );
        }
        else if (this.props.typ === "PSC") {
            return (
                <PanelHodnoty props={this.props}>
                    <TextField
                        onKeyPress={(event) => {
                            if (!/[0-9]\\s/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        type="number"
                        size="small"
                        onChange={(e) => this.props.onChange(e)}
                        value={this.props.hodnota}
                        disabled={this.props.disabled}
                        maxLength={5}
                        onFocus={event => {
                            if (!this.props.disabled) event.target.select();
                        }} />
                </PanelHodnoty>
            );
        }


        else if (this.props.typ === "TEXT") {
            return (
                <PanelHodnoty props={this.props}>
                    <TextField
                        size="small"
                        onChange={this.props.onChange}
                        value={this.props.hodnota}
                        disabled={this.props.disabled}
                        sx={{ width: "100%" }}
                        onFocus={event => {
                            if (!this.props.disabled) event.target.select();
                        }}
                    />
                </PanelHodnoty>
            );
        }

        else if (this.props.typ === "PASSWORD") {
            return (
                <PanelHodnoty props={this.props}>
                    <TextField
                        size="small"
                        onChange={this.props.onChange}
                        value={this.props.hodnota}
                        disabled={this.props.disabled}
                        sx={{ width: "100%" }}
                        onFocus={event => {
                            if (!this.props.disabled) event.target.select();
                        }}
                        type="password"
                    />
                </PanelHodnoty>
            );
        }

        else if (this.props.typ === "TEXTAREA") {
            return (
                <div className={styles.detail_udaj_container}>
                    <div className={styles.left} style={{ width: this.props.widthLeft }}>{this.props.popis} </div>
                    <div className={styles.right}>
                        <TextField
                            size="small"
                            multiline={true}
                            minRows={this.props.minRows ? this.props.minRows : 3}
                            onChange={this.props.onChange}
                            value={this.props.hodnota}
                            disabled={this.props.disabled}
                            sx={{ width: "100%" }}
                            onFocus={event => {
                                if (!this.props.disabled) event.target.select();
                            }}
                        />
                    </div>
                </div>
            );
        }

        else if (this.props.typ === "POZNAMKA") {
            return (
                <div className={styles.detail_udaj_container}>
                    <div className={styles.left_ta} style={{ width: this.props.widthLeft }}>{this.props.popis} </div>
                    <div className={styles.right_ta}>
                        <TextField
                            size="small"
                            multiline={true}
                            minRows={this.props.minRows ? this.props.minRows : 3}
                            onChange={this.props.onChange}
                            value={this.props.hodnota}
                            disabled={this.props.disabled}
                            sx={{ width: "100%" }}
                            onFocus={event => {
                                if (!this.props.disabled) event.target.select();
                            }}
                        />
                    </div>
                </div>
            );
        }

        else if (this.props.typ === "COMBOBOX") {
            return (
                <PanelHodnoty props={this.props}>
                    {this.props.dataCiselnik && <Combobox klicID={this.props.klicID} klicPopis={this.props.klicPopis} ID={this.props.hodnota} fill={this.props.fill} minWidth={this.props.minWidth} name={this.props.name} bEmptyOption={false} data={this.props.dataCiselnik} onChange={this.props.onChange} />}
                </PanelHodnoty>
            );
        }
        else if (this.props.typ === "NASEPTAVAC") {
            return (
                <PanelHodnoty props={this.props}>
                    {this.props.dataCiselnik &&
                       <Autocomplete
                        disablePortal
                        getOptionLabel={(option) => {
                            const zip = option.data.zip;

                            const municipality = option.data.regionalStructure.find(x => x.type === 'regional.municipality');

                            if (municipality) return option.value + ", " + municipality.name + "," + option.data.zip;

                            return option.value + " (" + option.data.zip + ")"
                        }}
                        options={this.props.dataCiselnik}                       
                        sx={{ width: 500 }}

                        onChange={(e, v) => this.props.onItemChange(v)}

                        renderInput={(params) => <TextField {...params} onChange={this.props.onChange} /> }
                    />}
                </PanelHodnoty>
            );
        }


        return (
            <PanelHodnoty props={this.props}>
                {this.props.hodnota}
            </PanelHodnoty>
        );
    }
}