import axios from "axios";
import { API_URL } from "./FetchWrapper";
import CacheData from "./CacheData";

import MessageBox from "./components/MessageBox/MessageBox";

const login = (login, password) => {
    console.log(API_URL);
  return axios
    .post(API_URL + "auth/login", {
      login,
      password
    })
      .then((response) => {
          if (response.data.accessToken) {
            sessionStorage.setItem("user", JSON.stringify(response.data));
          }

      return response.data;
    });
};

const logout = () => {
    //CacheData.DeleteData(getCurrentUser(), "klic");    
    sessionStorage.removeItem("user");
    //window.dispatchEvent(new Event('event_klic'));
};

const getCurrentUser = () => {
    const user = JSON.parse(sessionStorage.getItem("user"));

    try {
        if (user)
        {
            if (user.platnost && new Date(user.platnost) > new Date()) return user;

            MessageBox.open({
                title: "Chyba",
                content: <p>Vypršela platnost přihlášení.</p>,
                buttons: [
                    { name: "OK", handler: () => "OK" }
                ]
            });

            sessionStorage.removeItem("user");
            if (user) {
                //window.dispatchEvent(new Event('event_klic'));
                window.location.href = "/";
            }            
        }
    } catch { }

    return null;
};

const authService = {
  /*signup,*/
  login,
  logout,
  getCurrentUser,
};

export default authService;
