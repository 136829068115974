import React from 'react';
import AuthService from "../AuthService";
import { fetchWrapper } from "../FetchWrapper";
import { Radio, RadioGroup, FormControlLabel, TextField } from '@mui/material';

import Global from "./Global";
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";
import CacheData from "../CacheData";



export class FormLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = { login: "", password: "", typLog: "UZ" };

        this.handleLogin = this.handleLogin.bind(this);
    }



    handleLogin = async (e) => {
        e.preventDefault();


            LoadingSpinner.open();

            try {
                await AuthService.login(this.state.login, this.state.password)
                    .then(() => {
                        LoadingSpinner.close();
                        this.props.navigate('/', { replace: true });
                    },
                        (error) => {
                            console.log(error);

                            LoadingSpinner.close();
                            MessageBox.open({
                                title: "Chyba",
                                content: <p>Nesprávné přihlašovací údaje nebo problém s připojením.<br />{error.message}</p>,
                                buttons: [
                                    { name: "OK", handler: () => "OK" }
                                ]
                            });
                        }
                    );
            } catch (err) {
                LoadingSpinner.close();
                console.log(err);

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Chyba: {err}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            }
    };





    render() {

        return (
            <div>
                <div className='centerdiv-parent'>
                    <div className='centerdiv'>
                        <form onSubmit={this.handleLogin}>
                            <h2 style={{ textAlign:"center" }}>Přihlášení</h2>


                            <div>
                                <div className='odsazeni_bottom10 odsazeni_top10'>
                                    <TextField
                                        size="small"
                                        placeholder="přihlašovací jméno"
                                        onChange={e => this.setState({ login: e.target.value })}
                                        value={this.state.login}
                                        sx={{ width: "100%" }}
                                        onFocus={event => {
                                            if (!this.props.disabled) event.target.select();
                                        }}
                                    />
                                </div>

                                <div className='odsazeni_bottom10'>
                                    <TextField
                                        size="small"
                                        placeholder="heslo"
                                        onChange={e => this.setState({ password: e.target.value })}
                                        value={this.state.password}
                                        sx={{ width: "100%" }}
                                        onFocus={event => {
                                            if (!this.props.disabled) event.target.select();
                                        }}
                                        type="password"
                                    />
                                </div>

                                <button className="form_button" type="submit" style={{ width: "100%" }}>Přihlásit se</button>
                            </div>

                          

                        </form>
                    </div>
                </div>
            </div>
        );
    }

}